import React from 'react'
import { Link } from 'gatsby';
import Grid from '@material-ui/core/Grid';
import LayoutStatic from '../../../components/LayoutStatic';

const style = {
  card: {
    width: '18rem',
    margin: 'auto',
    marginTop: '20px',
  },
  button: {
    marginTop: '10px',
    marginRight: '10px',
    width: '45%'
  }
}

const InfantPage = () => {
  return (
    <LayoutStatic>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={6}>
          <div className="card w-100 mt-1">
            <img src="/img/InfantDevelopment.jpg" className="card-img-top bootstrap-card-img-top" alt="..." />
            <div className="card-body">
              <h5 className="card-title">ประเมินพัฒนาการ 4 ด้าน ทารกน้อย(0-11 เดือน)</h5>
              <p className="card-text">ทุกช่วงจังหวะของเจ้าตัวน้อย มีผลต่อพัฒนาการ เราจึงวรใส่ใจในมทุกๆช่วงวัย</p>
              <Link to="/questionnaire?questionnaireId=32" className="btn btn-primary" style={style.button}>0-3 เดือน</Link>
              <Link to="/questionnaire?questionnaireId=33" className="btn btn-primary" style={style.button}>4 เดือน</Link>
              <Link to="/questionnaire?questionnaireId=34" className="btn btn-primary" style={style.button}>5 เดือน</Link>
              <Link to="/questionnaire?questionnaireId=35" className="btn btn-primary" style={style.button}>6 เดือน</Link>
              <Link to="/questionnaire?questionnaireId=36" className="btn btn-primary" style={style.button}>7 เดือน</Link>
              <Link to="/questionnaire?questionnaireId=37" className="btn btn-primary" style={style.button}>8 เดือน</Link>
              <Link to="/questionnaire?questionnaireId=38" className="btn btn-primary" style={style.button}>9 เดือน</Link>
              <Link to="/questionnaire?questionnaireId=39" className="btn btn-primary" style={style.button}>10 เดือน</Link>
              <Link to="/questionnaire?questionnaireId=40" className="btn btn-primary" style={style.button}>11 เดือน</Link>
            </div>
          </div>
        </Grid>
      </Grid>
    </LayoutStatic>
  );
}

export default InfantPage

